import React, {memo, useMemo} from 'react';
import {IconName} from 'Icon/components/Icon';
import ColorPalette from 'Common/constants/ColorPalette';
import {IMenuItem} from 'Common/components/DropDown/common/types';
import AdminActions from 'Admin/AdminDashboard/components/shared/AdminActions/AdminActions';
import {copyToClipboard} from 'Common/helpers/copyToClipboard';
import {useToast} from 'Common/helpers/hooks/useToast';
import {getBaseUrl} from 'Common/helpers/getBaseUrl';
import {IMarketplaceAdminProduct} from './Products';

interface IExternalProps {
  product: IMarketplaceAdminProduct;
  editHandler: (productId: number) => void;
  deleteHandler: (productId: number) => void;
}

type AllProps = IExternalProps;

const iconProps = {color: ColorPalette.red7};

const menuItems: IMenuItem[] = [
  {
    value: 'edit',
    label: 'Edit product',
    icon: {name: IconName.Edit, ...iconProps},
  },
  {
    value: 'delete',
    label: 'Delete product',
    icon: {name: IconName.Delete, ...iconProps},
    divided: true,
  },
  {
    value: 'copyLink',
    label: 'Copy product link',
    icon: {name: IconName.FileCopy, ...iconProps},
  },
];

const ProductActions = ({product, editHandler, deleteHandler}: IExternalProps) => {
  const {id, productName} = product;
  const {addToast} = useToast();

  const actionsHandler = {
    edit: () => editHandler(id),
    delete: () => deleteHandler(id),
    copyLink: () => {
      copyToClipboard(`${getBaseUrl()}/marketplace/products/${id}`);
      addToast(`${productName} link copied to clipboard`);
    },
  };

  const onSelectHandler = (type: keyof typeof actionsHandler) => {
    actionsHandler[type]();
  };

  return <AdminActions menuItems={menuItems} onSelectHandler={onSelectHandler} />;
};

export default memo(ProductActions);
