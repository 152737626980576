import AdminDashboardLayout from 'Admin/AdminDashboard/components/AdminDashboardLayout';
import {AdminPermissionDomain} from 'Common/constants/AdminPermissionDomain';
import {AdminPermissionOperation} from 'Common/constants/AdminPermissionOperation';
import AdminAdvancedDashboardLayout from 'Admin/AdminAdvancedDashboard/components/AdminAdvancedDashboardLayout';
import AdminSettings from 'Admin/AdminSettings/components/AdminSettings';
import AdminDictionariesLayout from 'Admin/AdminReference/components/AdminDictionariesLayout';
import AdminPhenotypes from 'Admin/AdminPhenotypes/components/AdminPhenotypesSimple/AdminPhenotypes';
import AdminPhenotypesAdvanced from 'Admin/AdminPhenotypes/components/AdminPhenotypesAdvanced/AdminPhenotypesAdvanced';
import {INavigation} from 'Common/models/INavigation';
import OnlineReport from 'OnlineReport/components/OnlineReport';
import OnlineReportPrintable from 'OnlineReportPrintable/OnlineReportPrintable';
import NotFoundPage from 'NotFoundPage/components/NotFoundPage';
import CreateOrder from 'Order/components/CreateOrder/CreateOrder';
import {default as BusinessPortalCreateOrder} from 'BusinessPortal/components/BusinessPortalDashboard/Orders/CreateOrder/CreateOrder';
import {default as BusinessPortalOrderPayment} from 'BusinessPortal/components/BusinessPortalDashboard/Orders/OrderPayment/OrderPayment';
import ModalHorseProfile from 'HorseProfile/components/HorseProfileForm/HorseProfileFormInner';
import BuildHorse from 'BuildHorse/components/BuildHorse';
import BahSubscription from 'SubscriptionWelcome/components/BahSubscription/BahSubscription';
import {Permission} from 'Permissions/constants/Permission';
import FindHorse from 'FindHorse/components/FindHorseLayout';
import FoalProfile from 'FoalProfile/components/FoalProfile';
import BusinessPortalDashboard from 'BusinessPortal/components/BusinessPortalDashboard/BusinessPortalDashboard';
import {IconName} from 'Icon/components/Icon';
import AdminAssociations from 'Admin/AdminAssociations/components/AdminAssociations';
import OrderSuccessPlaced from 'Shared/components/OrderSuccess/OrderSuccessPlaced';
import OrderSuccessPaid from 'Shared/components/OrderSuccess/OrderSuccessPaid';
import {OrderPayment} from 'Payment/components';
import AdminAssociationHorseProfileLayout from 'Admin/AdminAssociations/components/Horses/HorseProfileLayout/AdminAssociationHorseProfileLayout';
import AdminHorseProfileLayout from 'Admin/AdminDashboard/components/Horses/HorseProfileLayout/AdminHorseProfileLayout';
import BusinessPortalHorseProfileLayout from 'BusinessPortal/components/BusinessPortalDashboard/Horses/HorseProfile/BusinessPortalHorseProfileLayout';
import HorseProfileLayout from 'HorseProfile/components/HorseProfileLayout/HorseProfileLayout';
import UserProfileLayout from 'UserProfile/components/UserProfileLayout';
import AdminUserProfileLayout from 'Admin/AdminDashboard/components/shared/UserProfileLayout/AdminUserProfileLayout';
import AdminAssociationOwnerProfileLayout from 'Admin/AdminAssociations/components/Owners/OwnerProfileLayout/AdminAssociationOwnerProfileLayout';
import BusinessPortalOwnerProfileLayout from 'BusinessPortal/components/BusinessPortalDashboard/Owners/OwnerProfile/BusinessPortalOwnerProfileLayout';
import {VisitorType} from 'Common/constants/VisitorType';
import BusinessPortalAssociationPage from 'BusinessPortal/components/BusinessPortalAssociationPage/BusinessPortalAssociationPage';
import {UserProfileType} from 'Shared/models/UserProfileType';
import BusinessPortalPersonalSettings from 'BusinessPortal/components/BusinessPortalPersonalSettings/BusinessPortalPersonalSettings';
import ProfileSettingsLayout from 'UserProfile/components/ProfileSettings/ProfileSettingsLayout';
// import MessageCenter from 'MessageCenter/components/MessageCenter';
import AdminSupportLayout from 'Admin/AdminSupport/components/AdminSupportLayout';
import NotificationHistory from 'Notifications/components/NotificationHistory';
import AncestryReportPrintable from 'OnlineReportPrintable/AncestryReportPrintable';
import AncestryFullReportPrintable from 'OnlineReportPrintable/AncestryFullReportPrintable';
import DiagnosticsReportPrintable from 'OnlineReportPrintable/DiagnosticsReportPrintable';
import {ActivateTestsLayout} from '../../ActivateTests/components/ActivateTestsLayout';
import MarketplaceDashboardLayout from '../../Admin/AdminMarketplace/MarketplaceDashboardLayout';
import AdminMarketplaceLayout from '../../Admin/AdminMarketplace/MarketplaceDashboardLayout';

export const PREFIX_VISITOR_TYPE: Record<VisitorType, string> = {
  [VisitorType.User]: '',
  [VisitorType.Admin]: '/admin',
  [VisitorType.AssociationAdmin]: '/admin-associations',
  [VisitorType.AssociationEmployee]: '/business-portal',
};

export const PREFIX_USER_PROFILE_TYPE: Record<UserProfileType, string> = {
  [UserProfileType.User]: 'user-profile',
  [UserProfileType.AssociationUser]: 'association-user-profile',
  [UserProfileType.AssociationOwner]: 'association-owner-profile',
};

export const buildAdminPermission = (domain: AdminPermissionDomain, operation?: AdminPermissionOperation) => {
  if (!operation) {
    return [
      `${domain}${AdminPermissionOperation.Read}`,
      `${domain}${AdminPermissionOperation.Create}`,
      `${domain}${AdminPermissionOperation.Delete}`,
      `${domain}${AdminPermissionOperation.Update}`,
    ];
  }

  return [`${domain}${operation}`];
};

export const BUSINESS_PORTAL_NAVIGATION_ROUTES: INavigation[] = [
  {value: '/business-portal/horse/:id', Component: BusinessPortalHorseProfileLayout},
  {value: '/business-portal/user/:userProfileType/:id', Component: BusinessPortalOwnerProfileLayout},
  {
    value: '/business-portal/order-tests/:horseIds?',
    Component: BusinessPortalCreateOrder,
  },
  {value: '/business-portal/order-placed/:token/', Component: OrderSuccessPlaced},
  {value: '/business-portal/order-placed/:token/payment', Component: OrderSuccessPaid},
  {
    value: '/business-portal/payment',
    Component: BusinessPortalOrderPayment,
  },
  {value: '/:reportType/online-report/:horseId/:orderId', Component: OnlineReport},
  {value: '/:reportType/online-report-printable/:horseId/:orderId', Component: OnlineReportPrintable},
  {value: '/:reportType/ancestry-report-printable/:horseId/:orderId', Component: AncestryReportPrintable},
  {value: '/:reportType/ancestry-full-report-printable/:horseId/:orderId', Component: AncestryFullReportPrintable},
  {
    value: '/business-portal/association-profile/*',
    Component: BusinessPortalAssociationPage,
    label: 'Association profile',
    icon: {name: IconName.AssociationPage, size: 16},
    order: 2,
  },
  {
    value: '/business-portal/dashboard/*',
    Component: BusinessPortalDashboard,
    label: 'Dashboard',
    icon: {name: IconName.Dashboard, size: 16},
    order: 1,
  },
  {
    value: '/business-portal/build-a-horse/:horseId?',
    Component: BuildHorse,
    permissions: [Permission.BuildHorse],
  },
  {
    value: '/business-portal/build-a-horse/*',
    Component: BuildHorse,
    label: 'Build-a-Horse',
    icon: {name: IconName.GenderSymbol, size: 20},
    permissions: [Permission.BuildHorse],
    order: 3,
  },
  {
    value: '/business-portal/find-a-horse/*',
    Component: FindHorse,
    label: 'Find-a-Horse',
    icon: {name: IconName.Search, size: 16},
    permissions: [Permission.FindHorse],
    order: 4,
  },
  {
    value: '/business-portal/foal/:firstHorseId?/:secondHorseId?',
    Component: FoalProfile,
    permissions: [Permission.BuildFoal],
  },
  {
    value: '/business-portal/personal-settings/*',
    Component: BusinessPortalPersonalSettings,
    label: 'Personal settings',
    icon: {name: IconName.Gear, size: 16},
    orderBottom: 1,
  },

  {
    value: '/business-portal/*',
    Component: BusinessPortalDashboard,
    label: 'Dashboard',
  },
];

export const COMMON_NAVIGATION_ROUTES: INavigation[] = [
  {value: '/:reportType/ancestry-report-printable/:horseId/:orderId/*', Component: AncestryReportPrintable},
  {value: '/:reportType/ancestry-full-report-printable/:horseId/:orderId/*', Component: AncestryFullReportPrintable},
  {value: '/:reportType/diagnostics-report-printable/:horseId/:orderId/*', Component: DiagnosticsReportPrintable},
  {value: '/:reportType/online-report/:horseId/:orderId/*', Component: OnlineReport},
  {value: '/:reportType/online-report-printable/:horseId/:orderId/*', Component: OnlineReportPrintable},
  {value: '/not-found', Component: NotFoundPage},
  {value: '/order-placed/:token/', Component: OrderSuccessPlaced},
  {value: '/order-placed/:token/payment', Component: OrderSuccessPaid},
  {value: '/payment', Component: OrderPayment},
];

export const USER_NAVIGATION_ROUTES: INavigation[] = [
  {value: '/horse/:id', Component: HorseProfileLayout},
  {value: '/user/:userProfileType/:id/*', Component: UserProfileLayout},
  {value: '/order-tests/:horseIds?', Component: CreateOrder},
  {value: '/horse-edit/:id', Component: ModalHorseProfile},
  {
    value: '/build-a-horse/:horseId?',
    Component: BuildHorse,
    RedirectComponent: BahSubscription,
    permissions: [Permission.BuildHorse],
  },
  {
    value: '/find-a-horse',
    Component: FindHorse,
    RedirectComponent: BahSubscription,
    permissions: [Permission.FindHorse],
  },
  {value: '/account-details/*', Component: ProfileSettingsLayout},
  {
    value: '/foal/:firstHorseId?/:secondHorseId?',
    Component: FoalProfile,
    RedirectComponent: BahSubscription,
    permissions: [Permission.BuildFoal],
  },
  // {value: '/message-center/:chatType?/:userId?', Component: MessageCenter},
  {value: '/notifications', Component: NotificationHistory},
  {value: '/activate-tests', Component: ActivateTestsLayout},
];

export const ADMIN_NAVIGATION_ROUTES: INavigation[] = [
  {value: '/admin/user/:userProfileType/:id/*', Component: AdminUserProfileLayout},
  {value: '/admin-associations/user/:userProfileType/:id', Component: AdminAssociationOwnerProfileLayout},
  {value: '/admin-associations/horse/:id', Component: AdminAssociationHorseProfileLayout},
  {value: '/admin/horse/:id', Component: AdminHorseProfileLayout},
  {
    label: 'Users',
    value: '/admin/*',
    Component: AdminDashboardLayout,
    permissions: buildAdminPermission(AdminPermissionDomain.Business, AdminPermissionOperation.Read),
  },
  {
    label: 'Marketplace',
    value: '/admin-marketplace/*',
    Component: AdminMarketplaceLayout,
    permissions: buildAdminPermission(AdminPermissionDomain.Business, AdminPermissionOperation.Read),
  },
  {
    label: 'Organizations',
    value: '/admin-associations/*',
    Component: AdminAssociations,
    permissions: buildAdminPermission(AdminPermissionDomain.BusinessPortal, AdminPermissionOperation.Read),
  },
  {
    label: 'Admins',
    value: '/admin-advanced/*',
    Component: AdminAdvancedDashboardLayout,
    permissions: buildAdminPermission(AdminPermissionDomain.Superior, AdminPermissionOperation.Read),
  },
  {
    label: 'Reference Data',
    value: '/dictionaries-admin/*',
    Component: AdminDictionariesLayout,
    permissions: buildAdminPermission(AdminPermissionDomain.Genetics, AdminPermissionOperation.Read),
  },
  {
    label: 'Genetics Base',
    value: '/admin-phenotypes/*',
    Component: AdminPhenotypes,
    permissions: buildAdminPermission(AdminPermissionDomain.Genetics, AdminPermissionOperation.Read),
  },
  {
    label: 'Genetics',
    value: '/admin-phenotypes-advanced/*',
    Component: AdminPhenotypesAdvanced,
    permissions: buildAdminPermission(AdminPermissionDomain.Genetics, AdminPermissionOperation.Read),
  },
  {
    label: 'System Settings',
    value: '/admin-settings/*',
    Component: AdminSettings,
    permissions: buildAdminPermission(AdminPermissionDomain.Superior, AdminPermissionOperation.Read),
    onlyForDevelopmentAdmin: true,
  },
  {
    label: 'Support',
    value: '/admin-support/*',
    Component: AdminSupportLayout,
    permissions: buildAdminPermission(AdminPermissionDomain.Superior, AdminPermissionOperation.Read),
  },
];
