import React, {memo, useEffect} from 'react';
import {Helmet} from 'react-helmet';

import BaseLayout from 'Common/components/BaseLayout/BaseLayout';
import {ITab} from 'Common/components/Tabs/ITab';
import AdminTabs from 'Admin/common/AdminTabs';
import AdminContent from 'Admin/common/AdminContent';

import {SelectedStrategy} from 'Common/helpers/strategy/SelectedStrategy';
import useDistributorStrategy from 'Common/helpers/strategy/useDistributorStrategy';
import AdminMarketplaceProductsTable from './components/Products';

const basePath = '/admin-marketplace';

const data: ITab[] = [{section: 'products', title: 'Products', Component: AdminMarketplaceProductsTable}];

const AdminMarketplaceLayout = () => {
  const {setStrategy} = useDistributorStrategy();

  useEffect(() => {
    setStrategy(SelectedStrategy.Admin);
  }, [setStrategy]);

  return (
    <BaseLayout withoutPaddings={true} widthByContent={true} isBackButtonDenied={true}>
      <Helmet>
        <title>Admin Marketplace</title>
      </Helmet>
      <AdminTabs tabs={data} basePath={basePath} />
      <AdminContent tabs={data} basePath={basePath} />
    </BaseLayout>
  );
};

export default memo(AdminMarketplaceLayout);
